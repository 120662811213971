<script>
	import axios from "axios"
	import moment from "moment"
	import Chart from 'chart.js/auto';

	export default {
		props: {
			campaign: {
				type: Object,
				required: true
			},
		},

		data() {
			return {
				expenses: [],
				isFetched: false
			}
		},

		methods: {
			fetchCampaignExpenses() {
					axios.get("/api/campaign_expenses", {
						params: { campaign_id: this.campaign.id }
					}
				).then(function (response) {
					if(response.status === 200) {
						this.expenses = response.data.expenses
						this.isFetched = true
						this.$nextTick(this.displayChart)
					}
				}.bind(this))
				.catch(function (error) {
					console.log(error)
				})
			},

			displayDate(date) {
				return moment(date).format("MMM Do")
			},

			displayChart() {
				if(this.expenses.length === 0) {
					return
				}

				const element = this.$refs.expenses.getContext("2d")
				const options = this.chartOptions()
				new Chart(element, options);
			},

			chartOptions() {
				const dataSets = [
			    {
			      label: 'Spend per day',
			      data: this.expenses.map(expense => expense.spent).filter(n => n),
		      	borderWidth: 1
		    	},
		    	{
						label: 'Cumulative spent',
						data: this.cumulativeSpent(),
						borderWidth: 1,
		        type: 'line'
					},
					{
						label: 'Trend line',
						data: this.trendLine(),
						borderWidth: 1,
		        type: 'line'
					}
		    ]

		    if(this.campaign.budget) {
		    	dataSets.push({
		    		label: 'Campaign budget',
		    		data: this.expenses.map(expense => this.campaign.budget),
		    		type: 'line'
		    	})
		    }

		    const labels = this.expenses.filter(expense => expense.spent && expense.spent > 0 ).map((expense) => {
		    	return moment(expense.spent_date).format('YYYY-MM-DD')
		    })

				return {
				  type: 'bar',
				  data: {
				    labels: labels,
				    datasets: dataSets
					}
				}
			},

			cumulativeSpent() {
				const cumulative = []
				let expenseTotal = 0

				this.expenses.forEach((expense) => {
					expenseTotal += expense.spent
					console.log("expenseTotal", expenseTotal)
					cumulative.push(expenseTotal)
				})

				console.log("cumulative", cumulative)
				return cumulative
			},

			trendLine() {
				const days = this.expenses.map(expense => parseFloat(moment(expense.spent_date).format('DD')))
				const productsSold = this.cumulativeSpent()

				// Calculate the means
				const xBar = days.reduce((a, b) => a + b, 0) / days.length;
				const yBar = productsSold.reduce((a, b) => a + b, 0) / productsSold.length;

				// Calculate the slope (m)
				const numerator = days.reduce((acc, x, i) => acc + (x - xBar) * (productsSold[i] - yBar), 0);
				const denominator = days.reduce((acc, x) => acc + Math.pow(x - xBar, 2), 0);
				const m = numerator / denominator;

				// Calculate the y-intercept (b)
				const b = yBar - m * xBar;

				// Calculate the trend line
				let trendLine = days.map(x => m * x + b)
				// trendLine.pop()

				return trendLine
			}
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})
			this.fetchCampaignExpenses()
		},
	}
</script>
<template>
	<div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h1 class="modal-title fs-5" id="staticBackdropLabel">
	        	{{ "Expenses of " + this.campaign.name }}
	        </h1>
	        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	      </div>
	      <div class="modal-body modal-body__scrollable">
	        <div class="p-2 bg-light rounded mb-3">
						All your expenses for this month are exposed below.
						If it not starts at the beginning of month it means the data is not available.
					</div>
					<template v-if="isFetched">
						<template v-if="expenses.length > 0">
							<canvas ref="expenses" v-if="isFetched && expenses.length > 0" width="600" height="400"/>
						</template>
						<div
							v-else
							class="text-center text-secondary"
						>
							No expenses
						</div>
					</template>
					<div
						v-else
						class="text-center text-secondary"
					>
						<div class="spinner-border" role="status">
							<span class="visually-hidden"></span>
						</div>
					</div>
	      </div>
	      <div class="modal-footer">
	        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
	      </div>
	    </div>
	  </div>
	</div>
</template>