<script>
	import axios from "axios"
	import ImagesHelper from "@/helpers/images_helper"

	export default {
		props: {
			title: {
				type: String,
				default: 'Locked Feature'
			},
			description: {
				type: String,
				required: true
			}
		},

		data: () => ({
			featureAsked: false,
		}),

		methods: {
			askForFeature() {
				axios.get("/features", {
					params: {
						feature: this.title
					}
				})
					.then(function (response) {
						if(response.status === 200) {
							this.featureAsked = true
						}
					}.bind(this))
					.catch(function (error) {
						console.log(error)
					})
			}
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})
		},
	}
</script>

<template>
	<div
		ref="myModal"
		class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true"
	>
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h1 class="modal-title fs-5" id="exampleModalLabel">
	        	{{ title + ' ' + 'feature' }}
	        </h1>
	        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	      </div>
	      <div class="modal-body">
	      	<h6>Description:</h6>
	      	<span v-html="description"></span>
	      	<div class="text-center">
	      		<button
	      			v-if="!featureAsked"
	      			@click="askForFeature()"
	      			class="btn btn-primary">
	      			Ask for this feature
	      		</button>
	      		<p v-else class="text-success">
	      			Your request has been sent to our team. We will get back to you soon.
	      		</p>
	      	</div>
	      </div>
	    </div>
	  </div>
	</div>
</template>