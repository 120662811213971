<script>
	export default {
		props: {
			totalPages: {
				type: Number,
				required: true
			},
			currentPage: {
				type: Number,
				required: true
			},
			isFetched: {
				type: Boolean,
				required: true
			}
		},

		methods: {
			changeCurrentPage(number) {
				if (number > 0 && number <= this.totalPages && number != this.currentPage) {
					this.$emit("changeCurrentPage", number)
				}
			}
		}
	}
</script>

<template>
	<div class="m-auto">
		<nav>
		  <ul class="pagination">
		    <li
		    	:class="{ disabled: currentPage === 1 || !isFetched, 'cursor-pointer': currentPage != 1 }"
		    	class="page-item"
		    >
		      <span
		      	@click="changeCurrentPage(currentPage - 1)"
		      	class="page-link"
		      >
		    		Previous
		    	</span>
		    </li>
		    <li
		    	v-for="page in totalPages"
		    	class="page-item"
		    >
		    	<span
		    		@click="changeCurrentPage(page)"
		    		:class="{ active: page === currentPage, disabled: !isFetched }"
		    		class="page-link cursor-pointer"
		    	>
		    		{{ page }}
		    	</span>
		    </li>
		    <li class="page-item">
		      <span
		      	@click="changeCurrentPage(currentPage + 1)"
		      	:class="{ disabled: currentPage === totalPages || !isFetched, 'cursor-pointer': currentPage != totalPages }"
		      	class="page-link"
		      >
		      	Next
		    	</span>
		    </li>
		  </ul>
		</nav>
	</div>
</template>