export default {
	daysInMonth() {
		let now = new Date()
		return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
	},

	daysElapsed() {
		let now = new Date()
		return now.getDate()
	},

	monthsElapsed() {
		let now = new Date()
		return now.getMonth() + 1
	}
}
