import { createWebHistory, createRouter } from "vue-router"
import GoogleAdsAccounts from "@/views/google/ads_accounts.vue"
import GoogleAdsAccountCampaigns from "@/views/google/ads_account/campaigns.vue"
import GoogleAdsAccountPerformanceReports from "@/views/google/ads_account/performance_reports.vue"
import DisplayCleaner from "@/views/google/display_cleaner.vue"
import PmaxAnalyzer from "@/views/google/pmax_analyzer.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
  	{ path: '/', component: GoogleAdsAccounts, name: "google_adds_accounts" },
    { path: '/ads_accounts/:id/campaigns',
      component: GoogleAdsAccountCampaigns,
      props: true,
      name: "google_ads_account_campaigns"
    },
    { path: '/ads_accounts/:id/performance_reports',
      component: GoogleAdsAccountPerformanceReports,
      props: true,
      name: "google_ads_account_performance_reports"
    },
    {
      path: '/display_cleaner',
      component: DisplayCleaner,
      name: "display_cleaner"
    },
    {
      path: '/pmax_analyzer',
      component: PmaxAnalyzer,
      name: "pmax_analyzer"
    }
  ]
});

export default router;
