<script>
	import axios from "axios"
	import datesHelper from "@/helpers/dates_helper"
	import campaignHelper from "@/helpers/campaigns_helper"

	export default {
		props: {
			account: {
				type: Object
			},
			displayable: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				error: false,
				isFetched: false,
				campaigns: [],
				removeAccountAlert: false,
				lastSevenDaysSpent: 0,
				editBudget: false,
				budget: null,
				currencyCode: null,
			}
		},

		computed: {
			isProduction() {
				return Adverte.environment === "production"
			},

			subscriptionLink() {
				let link
				if(this.isProduction) {
					link = "https://buy.stripe.com/6oE8zm22taXi3lKfZ2"
				} else {
					link = "https://buy.stripe.com/test_3cs4hs0Lp6BcaNG144"
				}

				return `${link}?client_reference_id=${Adverte.currentUser.id}`
			},

			billManagmentLink() {
				if(this.isProduction) {
					return "https://billing.stripe.com/p/login/00gbMDbyU5NZbWoaEE"
				} else {
					return "https://billing.stripe.com/p/login/test_6oE7sY02jeOiaPK144"
				}
			},

			isUserStripeCustomer(){
				return !!Adverte.currentUser.stripe_customer_id
			}
		},

		methods: {
			fetchAccountData() {
				axios.get(`/api/google/ads/${this.account.id}`)
				.then(function (response) {
					this.campaigns = response.data.campaigns
					if(this.campaigns.length > 0) {
						this.currencyCode = this.campaigns[0].currency_code
					}
					this.saveCampaigns(response.data.existing_campaigns)
				}.bind(this))
				.catch(function(error) {
					if(error.response){
						this.error = error.response.data.error
					}
				}.bind(this))
				.then(function (argument) {
					this.fetchLastSevenDaysSpent()
				}.bind(this))
			},

			fetchLastSevenDaysSpent() {
				axios.get(`/api/google/ads/${this.account.id}`, {
					params: { last_seven_days: true }
				})
				.then(function (response) {
					this.lastSevenDaysSpent = response.data.campaigns.reduce(
						(acc, campaign) => acc + campaign.metrics.cost_micros
					, 0)
				}.bind(this))
				.catch(function(error) {
					if(error.response){
						this.error = error.response.data.error
					}
				}.bind(this))
				.then(function (argument) {
					this.isFetched = true
				}.bind(this))
			},

			saveCampaigns(existingCampaigns) {
				let campaignsToUpdate = this.campaigns.filter(campaign => {
					return existingCampaigns.map(c => parseInt(c.google_id)).includes(campaign.google_id)
				})

				const campaignsToCreate = this.campaigns.filter(campaign => {
					return !existingCampaigns.map(c => parseInt(c.google_id)).includes(campaign.google_id)
				})

				if(campaignsToUpdate.length > 0) {
					campaignsToUpdate = campaignsToUpdate.map(campaign => {
						const existingCampaign = existingCampaigns.find(c => c.google_id == campaign.google_id)
						campaign.id = existingCampaign.id
						return campaign
					})

					this.updateCampaigns(campaignsToUpdate)
				}

				if(campaignsToCreate.length > 0) {
					this.createCampaigns(campaignsToCreate)
				}
			},

			updateCampaigns(campaigns){
				this.campaignsToUpdate = campaigns
				this.totalCampaignsToUpdate = campaigns.length
				this.updateCampaign()
			},

			createCampaigns(campaigns) {
				this.campaignsToCreate = campaigns
				this.totalCampaignsToCreate = campaigns.length
				this.createCampaign()
			},

			updateCampaign(campaign) {
				if(this.totalCampaignsToUpdate > 0) {
					const campaign = this.campaignsToUpdate[this.totalCampaignsToUpdate - 1]
					const csfrToken = document.querySelector('[name=csrf-token]').content
					axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
					axios.patch(`/api/google/ads_accounts/${this.account.id}/ads_accounts/campaigns/${campaign.id}`,
						{
							campaign: {
								budget_amount: campaign.budget,
								cost: campaign.metrics.cost_micros,
								status: campaign.status,
								name: campaign.name,
								advertising_channel_type: campaign.advertising_channel_type
							}
						}
					).then(function (response) {
						if(response.status === 200){
							this.totalCampaignsToUpdate--
							this.updateCampaign()
						}
					}.bind(this))
				}
			},

			createCampaign() {
				if(this.totalCampaignsToCreate > 0) {
					const campaign = this.campaignsToCreate[this.totalCampaignsToCreate - 1]

					const csfrToken = document.querySelector('[name=csrf-token]').content
					axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
					axios.post(`/api/google/ads_accounts/${this.account.id}/ads_accounts/campaigns`,
						{
							campaign: {
								google_id: campaign.google_id,
								name: campaign.name,
								budget_amount: campaign.budget,
								cost: campaign.metrics.cost_micros,
								currency_code: campaign.currency_code,
								status: campaign.status,
								advertising_channel_type: campaign.advertising_channel_type
							}
						}
					).then(function (response) {
						if(response.status === 200){
							this.totalCampaignsToCreate--
							this.createCampaign()
						}
					}.bind(this))
				}
			},

			integerToCurrency(value, currencyCode) {
				let amount
				if(value == null) {
					amount = "0"
				} else {
					amount = value
				}
				return `${amount} ${currencyCode}`
			},

			totalSpent() {
				let total = 0
				this.campaigns.forEach(function(campaign) {
					total += campaign.metrics.cost_micros
				})

				return Math.round(total)
			},

			totalBudget() {
				let total = 0
				this.campaigns.forEach(function(campaign) {
					total += campaign.budget
				})
				return this.integerToCurrency(total, this.currencyCode)
			},

			removeAccount() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
				axios.delete(`/api/google/ads_accounts/${this.account.id}`)
				.then(function (response) {
					if(response.data.success){
						Adverte.settings.exclusion_list_google_ads_accounts_ids = Adverte.settings.exclusion_list_google_ads_accounts_ids.filter(
							id => id !== this.account.id
						)
						Adverte.settings.perf_max_google_ads_accounts_ids = Adverte.settings.perf_max_google_ads_accounts_ids.filter(
							id => id !== this.account.id
						)
						const campaignsIds = this.campaigns.map(c => c.id)
						Adverte.settings.pinned_campaigns_ids = Adverte.settings.pinned_campaigns_ids.filter(id =>
							campaignsIds.includes(id)
						)
						this.$emit("removeAccount", this.account)
					} else {
						// TODO: to use catch
						console.log("error")
					}
				}.bind(this))
			},

			saveBudget() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
				axios.patch(`/api/google/ads_accounts/${this.account.id}`, {
					budget: this.budget
				})
					.then(function (response) {
						if(response.data.success) {
							this.editBudget = false
						} else {
							// TODO: to use catch
							console.log("error")
						}
					}.bind(this))
			},

			setBudget() {
				this.budget = this.account.budget
			},

			dailyTrendOfLastSeventDays() {
				return Math.round(this.lastSevenDaysSpent / 7)
			},

			forecastEndOfMonth() {
				const remainingDays = datesHelper.daysInMonth() - datesHelper.daysElapsed()
				return campaignHelper.forecastForRemainingDays(
					remainingDays, this.dailyTrendOfLastSeventDays(), this.totalSpent()
				)
			},

			forecastDeltaWithBudget() {
				return (this.budget || 0) - this.forecastEndOfMonth()
			},

			forecastDeltaWithBudgetColor() {
				const resultPercentage = (this.forecastDeltaWithBudget() / this.forecastEndOfMonth()) * 100
				const result = Math.abs(Math.round(resultPercentage, 2))

				if( result < 2) {
					return "success"
				} else if(result < 4) {
					return "warning"
				} else {
					return "danger"
				}
			},

			insight() {
				const remainingDaysForMonth = datesHelper.daysInMonth() - datesHelper.daysElapsed()
				return Math.round(
					(
						this.forecastDeltaWithBudget() /
						remainingDaysForMonth
					)
				)
			},

			insightTrendPercentage() {
				const result = (this.insight() / this.dailyTrendOfLastSeventDays()) * 100
				return Math.round(Math.abs(result), 2)
			},

			insightDeltaWithBudgetPercentage() {
				const result = (this.forecastEndOfMonth() / this.budget) * 100
				return Math.round(result, 2)
			},

			formatNumberWithCurrency(number) {
				return new Intl.NumberFormat("fr-FR",
					{ style: "currency", currency: this.currencyCode, minimumFractionDigits: 0 }
				).format(
			    number,
			  )
			}
		},

		mounted() {
			if(this.displayable) {
				this.fetchAccountData()
			} else {
				this.isFetched = true
			}
			this.setBudget()
		}
	}
</script>
<template>
	<div
		:class="{ 'bg-light': !displayable }"
		class="card"
	>
		<div class="card-header">
			<div class="close-icon">
				<div
					v-if="!removeAccountAlert"
					@click="removeAccountAlert = true"
					class="btn-close"
				/>
				<div
					v-else
					class="text-danger pe-3"
				>
					Remove account:
					<span class="cursor-pointer ms-2 me-3" @click="removeAccount()">Yes</span>
					<span class="cursor-pointer text-success" @click="removeAccountAlert = false">No</span>
				</div>
			</div>
			<div class="d-flex justify-content-between align-items-center mb-3">
				<h3 class="mb-0">{{ account.name }}</h3>
				<div v-if="isFetched">
					<p class="my-auto">
						<router-link
							v-if="campaigns.length > 0"
							:to="`/ads_accounts/${account.id}/campaigns`"
						>
							Campaign details
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
							</svg>
						</router-link>
					</p>
					<p class="my-auto">
						<router-link
							:to="`/ads_accounts/${account.id}/performance_reports`"
						>
							Performance reports
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
							</svg>
						</router-link>
					</p>
				</div>
			</div>
		</div>
		<div class="card-body pt-0">
			<template v-if="isFetched">
				<div v-if="error" class="text-danger">
					{{ error }}
				</div>
				<template v-else>
					<template v-if="displayable">
						<template v-if="campaigns.length > 0">
							<div class="mb-3">
								<h5 class="mb-2">Budget</h5>
								<div class="mb-2">
									<span class="text-muted me-2">
										<small>Total spent:</small>
									</span>
									<span class="fw-semibold">
										{{ formatNumberWithCurrency(totalSpent()) }}
									</span>
								</div>
								<div class="mb-2">
									<span class="text-muted me-2">
										<small>Monthly budget:</small>
									</span>
									<span
										v-if="editBudget"
										class="input-group input-group-sm mb-3"
									>
									  <span class="input-group-text" id="inputGroup-sizing-sm">
									  	{{ this.currencyCode }}
									  </span>
									  <input
									  	v-model="budget"
									  	type="number"
									  	class="form-control"
									  	aria-label="1000"
									  	aria-describedby="inputGroup-sizing-sm"
									  >
									  <button
									  	@click="saveBudget()"
									  	class="btn btn-primary btn-sm"
									  >
									  	Save
										</button>
										<button
											@click="editBudget = false"
											class="btn btn-light btn-sm"
										>Cancel
										</button>
									</span>
									<span v-else>
										<template v-if="budget">
											{{ formatNumberWithCurrency(budget) }}
										</template>
										<template v-else>
											No budget
										</template>
										<span
											@click="editBudget = true"
											class="cursor-pointer ms-2"
										>
											<i class="bi bi-pen"></i>
										</span>
									</span>
								</div>
							</div>
							<div class="d-flex justify-content-between">
								<div class="mb-3">
									<h5 class="mb-2">Trend</h5>
									<div class="mb-2">
										<span class="text-muted me-2">
												<small>Total spent last 7 days:</small>
										</span>
										<span class="fw-semibold">
											{{ formatNumberWithCurrency(lastSevenDaysSpent) }}
										</span>
									</div>
									<span class="text-muted me-2">
										<small>Daily trend last 7 days:</small>
									</span>
									<span class="fw-semibold">
										{{ formatNumberWithCurrency(dailyTrendOfLastSeventDays()) }}
									</span>
								</div>
								<div class="mb-3">
									<h5 class="mb-2">Forecast</h5>
									<div class="mb-2">
										<span class="text-muted me-2">
											<small>End of month:</small>
										</span>
										<span class="fw-semibold">
											{{ formatNumberWithCurrency(forecastEndOfMonth()) }}
										</span>
									</div>
									<span class="text-muted me-2">
										<small>Delta with budget:</small>
									</span>
									<span
										v-if="budget"
										:class="`text-${forecastDeltaWithBudgetColor()}`"
										class="fw-semibold"
									>
										{{ formatNumberWithCurrency(forecastDeltaWithBudget()) }}
									</span>
									<template v-else>-</template>
								</div>
							</div>
							<div class="mt-3 border-top">
								<h5 class="my-3">🎯 Insight</h5>
								<template v-if="budget">
									Based on the last 7 days trend, the account will spend
									<span class="fw-semibold">
										{{ insightDeltaWithBudgetPercentage() + " %" }}
									</span>
									of the monthly budget.
									<br>
									It's recommended to
									{{ forecastEndOfMonth() > budget ? "decrease" : "increase" }}
									the daily budget of
									<span class="fw-semibold">
										{{ formatNumberWithCurrency(insight()) }}
									</span>
									<template v-if="dailyTrendOfLastSeventDays() > 0">
										, i.e
										{{ forecastEndOfMonth() > budget ? "a decrease" : "an increase" }}
										of
										<span class="fw-semibold">
											{{ insightTrendPercentage() + " %"}}
										</span>
										of the current daily budget.
									</template>
								</template>
								<template v-else>
									You should add a budget to have an insight
								</template>
							</div>
						</template>
						<div v-else class="text-center">
							No Campaigns
						</div>
					</template>
					<template v-else>
						<div class="text-center">
							You reached the limit of 2 accounts.
							<p>To add more accounts, please upgrade your plan.</p>
							<div>
								<a
		         		v-if="isUserStripeCustomer"
	         			:href="billManagmentLink"
		         			class="btn btn-primary"
		         		>
		          		<span class="nav-item-title pe-0">
		            		Manage my bills
		            	</span>
		            </a>
		          	<a
		          		v-else
		          		:href="subscriptionLink"
		          		class="btn btn-primary"
		          	>
		          		<span class="nav-item-title pe-0">
		            		⚡ Subscribe
		            	</span>
		          	</a>
							</div>
						</div>
					</template>
				</template>
			</template>
			<div v-else class="d-flex justify-content-center text-secondary">
				<span class="me-2">Fetching</span>
				<div class="spinner-border" role="status">
					<span class="visually-hidden"></span>
				</div>
			</div>
		</div>
	</div>
</template>
