<script>
	import axios from "axios"

	export default {
		props: {
			accountId: {
				type: Number,
				required: true
			},
		},

		data() {
			return {
				account: null,
				isFetched: false,
				isExporting: false
			}
		},

		methods: {
			fetchGoogleAdsAccounts() {
        console.log(this.accountId)
	  		axios.get(`/api/google/ads_accounts/${this.accountId}`)
	  			.then(function (response) {
	  				if(response.status == 200) {
	  					this.account = response.data
	  					this.isFetched = true
	  				}
	  			}.bind(this))
	  	},

	  	removeAccount() {
	  		this.$emit('removeAccount', this.accountId)
	  	},

      generateRapport() {
				this.isExporting = true
        setTimeout(() => (this.isExporting = false), 5000)
        window.location = `/api/google/ads_accounts/${this.accountId}/export_rapport`
      }
		},

		mounted() {
			this.fetchGoogleAdsAccounts()
		}
	}
</script>

<template>
	<div class="card">
		<div v-if="isFetched">
			<div class="card-header">
				<div
					@click="removeAccount()"
					class="btn-close float-end"></div>
				<h3 class="mb-0">{{ account.name }}</h3>
			</div>
			<div class="card-body">
        <button
        @click="generateRapport()"
        class="btn btn-primary"
				:disabled="isExporting"
      >
				<span v-if="isExporting" class="spinner-border spinner-border-sm"></span>
        Generate rapport
      </button>
			</div>
		</div>
	</div>
</template>