<script>
  import axios from "axios"
  import { createApp } from 'vue/dist/vue.esm-bundler';

  //Vues
  import SelectableAccountsModal from "@/views/google/selectable_accounts_modal.vue"
  import PmaxAccount from "@/views/google/pmax_account.vue"

  export default {
    components: { PmaxAccount },

    computed: {
			userSettingsId() {
				return Adverte.settings_id
			},
		},

    data() {
      return {
        perfMaxGoogleAdsAccountsIds: [],
      }
    },

    methods: {
      openMyModal() {
        const element = document.createElement("div")
        document.body.appendChild(element)
        const view = createApp(SelectableAccountsModal, {
          onHide: (e) => element.remove(),
          onAddAccounts: (accounts) => { this.addAccounts(accounts) },
          accountsIdsToDisable: this.perfMaxGoogleAdsAccountsIds,
          onlyWithPmaxCampaigns: true
        }).mount(element)
      },

      addAccounts(accounts) {
      	accounts.forEach((account) => {
					if(!this.perfMaxGoogleAdsAccountsIds.includes(account.id)) {
						this.perfMaxGoogleAdsAccountsIds.push(account.id)
					}
				})
      	this.saveUserSettings()
			},

      removeAccount(accountId) {

      },

      saveUserSettings() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken

				axios.patch(`/api/user/settings/${this.userSettingsId}`, {
					perf_max_google_ads_accounts_ids: this.perfMaxGoogleAdsAccountsIds,
				})
					.then(function (response) {
						if(response.status == 200) {
							Adverte.settings.perf_max_google_ads_accounts_ids = this.perfMaxGoogleAdsAccountsIds
						}
					}.bind(this))
			},
    },
    mounted() {
			this.perfMaxGoogleAdsAccountsIds = Adverte.settings.perf_max_google_ads_accounts_ids
		}
  }
</script>
<template>
  <div class="container my-5">
		<h2 class="mb-1">Perf Max analzser</h2>
		<p class="mb-3">Analyze your PMax campaign to take better decisions</p>
    <div class="d-flex justify-content-end">
      <button
        @click="openMyModal()"
        class="btn btn-primary"
      >
        Ads accounts
      </button>
    </div>
    <div class="row">
			<template v-if="perfMaxGoogleAdsAccountsIds.length > 0">
				<div class="mb-3 col-12">
					<div
						v-for="perfMaxGoogleAdsAccountsId in perfMaxGoogleAdsAccountsIds"
						:key="perfMaxGoogleAdsAccountsId"
						class="col-12 col-md-6 mb-5"
					>
						<PmaxAccount
							:accountId="perfMaxGoogleAdsAccountsId"
							@removeAccount="removeAccount"
						>
						</PmaxAccount>
					</div>
				</div>
			</template>
			<div v-else class="col-12 text-center py-5">
				You have no accounts selected.
			</div>
		</div>
  </div>
</template>
