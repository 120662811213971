<script>
  import axios from "axios"
  import moment from "moment"

  const PRECONISATIONS = [
      "Budget increase",
      "Creation of a Performance Max campaign",
      "Creation of a Youtube Ads campaign",
      "Creation of a Search campaign related to brand awareness",
      "Creation of a Search campaign related to another product",
      "Strengthening of a budget in a sector",
      "Modification of the landing page (due to low quality score?)",
      "Creation of a new landing page for the campaigns",
      "Changing the visuals of the existing campaigns",
      "Creating a new video for the campaigns",
      "Testing a new audience",
      "Proposing a direct email marketing campaign on its website",
      "Conducting a retargeting campaign"
  ]

	export default {
		name: "PerformanceReportBuilderModal",
		props: {
      googleAdsAccountId: {
        type: [Number, String],
        required: true
      },
      createMode: {
        type: Boolean,
        default: true,
        required: false
      },
      reportData: {
        type: Object,
        required: false
      }
		},

    computed: {
      PRECONISATIONS() {
        return PRECONISATIONS
      },
      reportTemplate() {
        return window.Adverte.settings.performance_report_template
      }
    },

		data() {
      return {
        dateFrom: null,
        dateTo: null,
        isFetching: false,
        isFetched: false,
        report: null,
        preconisations: []
      }
		},

		methods: {
      buildReport() {
        this.isFetching = true
        const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken

        axios.post(`/api/google/ads_accounts/${this.googleAdsAccountId}/ads_accounts/performance_reports`,
          {
            from: this.dateFrom,
            to: this.dateTo
          }
        )
          .then(function (response) {
            if(response.status == 200) {
              this.report = response.data
              this.isFetching = false
              this.$emit('report-created', this.report)
            }
        }.bind(this))
      },

      updateReport() {
        const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
        axios.put(`/api/google/ads_accounts/${this.googleAdsAccountId}/ads_accounts/performance_reports/${this.report.id}`, {
          preconisations: this.preconisations
        })
          .then(function (response) {
            if(response.status == 200) {
              console.log(response.data)
            }
        }.bind(this))
      },

      addPreconisation(preconisation) {
        if(this.preconisations.includes(preconisation)) {
          this.preconisations = this.preconisations.filter(p => p !== preconisation)
        } else {
          this.preconisations.push(preconisation)
        }
      },

      buildReportTemplate() {
        const dates = `${this.report.monthes} - ${moment(this.report.to).year()}`
        const filledReportTemplate = this.reportTemplate.replace(/{{dates}}/, dates)
          .replace(/{{clicks}}/, this.report.clicks)
          .replace(/{{conversions}}/, this.report.conversions)
          .replace(/{{cost}}/, this.report.cost)
          .replace(/{{cost_per_click}}/, this.report.cost_per_click)
          .replace(/{{impressions}}/, this.report.impressions)
          .replace(/{{zones}}/, this.zones())
          .replace(/{{preconisations}}/, this.preconisations.join(', '))
          .replace(/&lt;br&gt;/g, '<br/>')
        return filledReportTemplate
      },
      zones() {
        return this.report.zones.split(",").join(", ").replace("[", '').replace("]", '')
      }
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})
      if(this.reportData) {
        this.report = this.reportData
        this.preconisations = this.reportData.preconisations
      }
		}
	}
</script>
<template>
	<div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
	  <div class="modal-dialog modal-lg">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h1 class="modal-title fs-5" id="staticBackdropLabel">
            <template v-if="createMode">
              Create report
            </template>
            <template v-else>
              Update Report
            </template>
          </h1>
	        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	      </div>
	      <div class="modal-body">
          <template v-if="createMode">
            <p class="fw-bold">Chose a start and a end date for your report:</p>
            <div class="d-flex justify-content-between">
              <div class="input-group input-group-sm">
                <span class="input-group-text">From</span>
                <input
                  v-model="dateFrom"
                  type="date" class="form-control">
              </div>
              <div class="input-group input-group-sm">
                <span class="input-group-text">To</span>
                <input
                  v-model="dateTo"
                  type="date" class="form-control">
              </div>
              <button
                @click="buildReport()"
                :disabled="!dateTo || !dateFrom"
                class="btn btn-primary btn-xs ms-2">
                Generate
              </button>
            </div>
          </template>
          <div v-if="isFetching" class="text-center mt-3">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <template v-if="report">
            <ul class="list-group mt-4">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Clicks:
                <span class="badge bg-primary rounded-pill">{{ report.clicks }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Conversion:
                <span class="badge bg-primary rounded-pill"> {{report.conversions }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Cost:
                <span class="badge bg-primary rounded-pill">{{ report.cost }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Cost per click:
                <span class="badge bg-primary rounded-pill">{{ report.cost_per_click ? report.cost_per_click.toFixed(2) : 0 }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Impressions:
                <span class="badge bg-primary rounded-pill">{{ report.impressions }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Monthes:
                <span>{{ report.monthes }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Zones:
                <span>{{ zones() }}</span>
              </li>
            </ul>
            <p class="fw-bold mt-4">Preconisations:</p>
            <template v-for="preconisation in PRECONISATIONS">
              <div class="form-check">
                <input
                  @click="addPreconisation(preconisation)"
                  type="checkbox"
                  :checked="preconisations.includes(preconisation)"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                >
                  {{ preconisation }}
                </label>
              </div>
            </template>
            <template v-if="reportTemplate">
              <p class="fw-bold mt-3">Report in text:</p>
              <div class="bg-light p-3 rounded-4">
                <p v-html="buildReportTemplate()"/>
              </div>
            </template>
          </template>
	      </div>
	      <div class="modal-footer">
	        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
	        <button
            @click="updateReport()"
            :disabled="preconisations.length == 0 || !report"
	        	type="button"
	        	class="btn btn-primary"
	        	data-bs-dismiss="modal"
	        >Update with preconisation</button>
	      </div>
	    </div>
	  </div>
	</div>
</template>