<script>
	import { createApp } from 'vue/dist/vue.esm-bundler';
	import axios from "axios"

	// Helpers
	import ImagesHelper from "@/helpers/images_helper"

	// Vues
	import LockedFeaturesModal from "@/views/utilities/locked_features_modal.vue"

	export default {
		data() {
			return {
				activeLink: null,
				lockedFeatures: [
					"404 Alerts",
					"Quality score checker"
				],
			}
		},

		computed: {
			isProduction() {
				return Adverte.environment === "production"
			},

			subscriptionLink() {
				let link
				if(this.isProduction) {
					link = "https://buy.stripe.com/6oE8zm22taXi3lKfZ2"
				} else {
					link = "https://buy.stripe.com/test_3cs4hs0Lp6BcaNG144"
				}

				return `${link}?client_reference_id=${Adverte.currentUser.id}`
			},

			billManagmentLink() {
				if(this.isProduction) {
					return "https://billing.stripe.com/p/login/00gbMDbyU5NZbWoaEE"
				} else {
					return "https://billing.stripe.com/p/login/test_6oE7sY02jeOiaPK144"
				}
			},

			isUserStripeCustomer(){
				return !!Adverte.currentUser.stripe_customer_id
			}
		},

		methods: {
			adverteLogo() {
				return ImagesHelper.defaultAdverteLogo()
			},

			googleAdsLogo() {
				return ImagesHelper.defaultGoogleAdsLogoDark()
			},

			metaLogo() {
				return ImagesHelper.metaLogoDark()
			},

			userToken() {
				return document.querySelector('[name=csrf-token]').content
			},

			openLockedFeatureModalFor(feature){
				let featureDescription

				switch (feature) {
				  case "404 Alerts":
						featureDescription = `
							<p>
								Checks if the your URLs don't generate a 404 error 'Page not found' or other types of error responses.
							</p>
							<p>
								When a URL generates an error, it notifies you by email. We also save the scanned results.
							</p>
						`
						break;
				  case "Quality score checker":
				  	featureDescription = `
							<p>
								It keeps track of an account Quality Scores per click and per impression.
							</p>
							<p>
								It also displays a graph of the overall quality and its evolution over time.
							</p>
						`
						break;
				  case "Performance Max Analyser":
				    featureDescription = `
				    	<p>
				    		See how Google prioritizes campaign types (% allocation) over the last 30 days.
				    	</p>
							<p>
								The analyser will provide insights to explain variations in the performances.
							</p>
				    `
				    break;
				  case "Automatic Negative Display Placements":
				  	featureDescription = `
				  		<p>
				  			It analyses the display placements on all accounts and identifies the low CTR / engagement rate website.
				  		</p>
							<p>
								It also allows to block the display on the poor CTR websites directly in one click.
							</p>
				  	`
				}

				this.showModal(LockedFeaturesModal, {
					title: feature,
					description: featureDescription
				})
			},

			showModal(modalComponent, data) {
				const element = document.createElement("div")
				let behaviours = {
	      	onHide: (e) => element.remove(),
				}

	      document.body.appendChild(element)
	      createApp(modalComponent, {
	      	...behaviours,
	      	...data
	      }).mount(element)
			},
		},

		mounted() {
			this.activeLink = window.location.href.split("/").pop() || "google_adds_accounts"
		}
	}
</script>

<template>
	<div
		class="flex-shrink-0 p-3 bg-white d-none d-xl-block box-shadow-right"
		style="width: 250px;"
	>
		<div class="sticky-top">
		  <a href="/" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
		  	<img :src="adverteLogo()" style="height: 60px">
		    <span class="fs-5 fw-semibold">Adverte</span>
		  </a>
		  <ul class="list-unstyled ps-0">
		    <li class="mb-1">
		      <button class="btn btn-toggle d-flex align-items-center rounded border-0 collapsed w-100 py-2" data-bs-toggle="collapse" data-bs-target="#google-ads" aria-expanded="true">
		        <div class="me-auto d-flex align-items-center">
		        	<img :src="googleAdsLogo()" style="height: 19px">
		        	<span class="nav-item-title">Google Ads</span>
		        </div>
		      </button>
		      <div class="collapse show" id="google-ads">
		        <ul class="ps-0 py-1">
		          <li
		            :class="{ 'nav-link--active': activeLink == 'google_adds_accounts' }"
		            class="btn-toggle-nav nav-link d-inline-flex align-items-center py-2 px-3 w-100 rounded border-0"
		          >
		            <i class="bi bi-circle"></i>
		            <router-link
		            	to="/"
		            	@click="activeLink = 'google_adds_accounts'"
		            	class="ms-0 ps-0 py-0 my-0 w-100"
		            >
		            	<span class="nav-item-title pe-0">
		            		Accounts
		            	</span>
								</router-link>
		          </li>
		          <li
		            :class="{ 'nav-link--active': activeLink == 'display_cleaner' }"
		            class="btn-toggle-nav nav-link d-inline-flex align-items-center py-2 px-3 w-100 rounded border-0"
		          >
		            <i class="bi bi-circle"></i>
		            <router-link
		            	to="/display_cleaner"
		            	@click="activeLink = 'display_cleaner'"
		            	class="ms-0 ps-0 py-0 my-0 w-100"
		            >
		            	<span class="nav-item-title pe-0">
		            		Display cleaner
		            	</span>
								</router-link>
		          </li>
		          <li
		            :class="{ 'nav-link--active': activeLink == 'pmax_analyzer' }"
		            class="btn-toggle-nav nav-link d-inline-flex align-items-center py-2 px-3 w-100 rounded border-0"
		          >
		            <i class="bi bi-circle"></i>
		            <router-link
		            	to="/pmax_analyzer"
		            	@click="activeLink = 'pmax_analyzer'"
		            	class="ms-0 ps-0 py-0 my-0 w-100"
		            >
		            	<span class="nav-item-title pe-0">
		            		Perf Max Analyzer
		            	</span>
								</router-link>
		          </li>
		          <li
		          	v-for="feature in lockedFeatures"
		          	class="btn-toggle-nav nav-link d-flex align-items-center py-2 px-3 w-100 rounded border-0">
		          	<i class="bi bi-circle"></i>
		          	<span class="nav-item-title pe-0">
		            	{{ feature }}
		            </span>
		            <div class="ms-auto">
		            	<i
		            		@click="openLockedFeatureModalFor(feature)"
		            		class="bi bi-lock cursor-pointer"
		            	></i>
		            </div>
		          </li>
		        </ul>
		      </div>
		    </li>
		    <li class="mb-1">
		      <button class="btn btn-toggle__locked-icon d-flex align-items-center rounded border-0 w-100 py-2">
		        <div class="me-auto d-flex align-items-center">
		        	<img :src="metaLogo()" style="height: 19px">
		        	<span class="nav-item-title">Meta</span>
		        </div>
		      </button>
		    </li>
		    <li class="mb-1">
		      <button class="btn btn-toggle__locked-icon d-flex align-items-center rounded border-0 w-100 py-2">
		        <div class="me-auto d-flex align-items-center">
			        <i class="bi bi-exclamation-circle"></i>
			        <span class="nav-item-title">Alerts</span>
		        </div>
		      </button>
		    </li>
		    <li class="mb-1">
		      <button class="btn btn-toggle d-flex align-items-center rounded border-0 collapsed w-100 py-2" data-bs-toggle="collapse" data-bs-target="#user" aria-expanded="true">
		        <div class="me-auto d-flex align-items-center">
		        	<i class="bi bi-person"></i>
		        	<span class="nav-item-title">Me</span>
		        </div>
		      </button>
		      <div class="collapse show" id="user">
		        <ul class="ps-0 py-1">
		        	<li
		          	class="btn-toggle-nav nav-link d-flex align-items-center py-2 px-3 w-100 rounded border-0"
		          >
		          	<i class="bi bi-circle"></i>
		         		<a
		         			v-if="isUserStripeCustomer"
		         			:href="billManagmentLink"
		         			class="m-0"
		         		>
		          		<span class="nav-item-title pe-0">
		            		Manage my bills
		            	</span>
		            </a>
		          	<a
		          		v-else
		          		:href="subscriptionLink"
		          		class="m-0"
		          	>
		          		<span class="nav-item-title pe-0">
		            		⚡ Subscribe
		            	</span>
		          	</a>
		          </li>
		        	<li
		          	class="btn-toggle-nav nav-link d-flex align-items-center py-2 px-3 w-100 rounded border-0"
		          >
		          	<i class="bi bi-circle"></i>
		          	<span class="nav-item-title pe-0">
		            	<form method='post' action="/users/sign_out">
						      	<input name='_method' value='delete' type='hidden' />
						       	<input value='Log out' type='submit' class="btn btn-sm btn-secondary" />
						        <input name="authenticity_token" type="hidden" :value="userToken()"/>
						     	</form>
		            </span>
		          </li>
		        </ul>
		      </div>
		    </li>
		  </ul>
    </div>
	</div>
</template>