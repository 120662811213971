<script>
	import axios from "axios"
	import { createApp } from 'vue/dist/vue.esm-bundler';

	// Helpers
	import datesHelper from "@/helpers/dates_helper"
	import campaignHelper from "@/helpers/campaigns_helper"

	// Components
	import ExpensesModal from "@/views/google/ads_account/campaign_expenses_modal.vue"

	export default {
		components: {
			ExpensesModal
		},
		emits: ["pinCampaign", "budgetDifference"],
		props: {
			campaign: {
				type: Object,
				required: true
			},
			pinned: {
				type: Boolean,
				required: false,
				default: false
			},
			adAccountBudget: {
				type: Number,
				required: false
			}
		},

		data() {
			return {
				budget: this.campaign.budget,
				editBudget: false,
				budgetPercentage: null,
				editBudgetPercentage: false,
			}
		},

		computed: {
			campaignBadgeStatusColor() {
				const status = this.campaign.status

				if(status == "ENABLED") {
					return "primary"
				} else if(status == "PAUSED") {
					return "warning"
				} else if(status == "REMOVED") {
					return "danger"
				} else {
					return "secondary"
				}
			},
		},

		methods: {
			dailyTrendOfLastSeventDays() {
				return Math.round(this.campaign.last_seven_days_spent / 7)
			},

			forecastEndOfMonth() {
				const remainingDays = datesHelper.daysInMonth() - datesHelper.daysElapsed()
				return campaignHelper.forecastForRemainingDays(remainingDays, this.dailyTrendOfLastSeventDays(), this.campaign.cost)
			},

			forecastDeltaWithBudget() {
				return (this.budget || 0) - this.forecastEndOfMonth()
			},

			insight() {
				const remainingDaysForMonth = datesHelper.daysInMonth() - datesHelper.daysElapsed()
				return Math.round(
					(
						this.forecastDeltaWithBudget() /
						remainingDaysForMonth
					)
				)
			},

			totalSpent() {
				return campaign.cost
			},

			formatNumberWithCurrency(amount, currency) {
				return new Intl.NumberFormat("fr-FR",
					{ style: "currency", currency: currency, minimumFractionDigits: 0 }
				).format(amount)
			},

			saveBudget() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken

				axios.patch(`/api/google/ads_accounts/${this.campaign.google_ads_account_id}/ads_accounts/campaigns/${this.campaign.id}`, {
						campaign: {
							budget: this.budget
						}
					})
					.then(function (response) {
						if(response.status == 200) {
							this.setBudget(response.data.budget)
							this.editBudget = false
							this.setBudgetInAccountBudgetPercentage(response.data.budget)
						}
					}.bind(this))
					.catch(function (error) {
						console.log(error)
					})
			},

			saveBudgetPercentage() {
				this.budget = this.adAccountBudget * this.budgetPercentage / 100
				this.saveBudget()
				this.editBudgetPercentage = false
			},

			setBudgetInAccountBudgetPercentage(number) {
				if(number !== null && this.adAccountBudget) {
					this.budgetPercentage = ((number / this.adAccountBudget) * 100).toFixed(1)
				} else {
					this.budgetPercentage = null
				}
			},

			closeBudgetEdition() {
				this.editBudget = false
				this.budget = this.campaign.budget
			},

			closeBudgetPercentageEdition() {
				this.editBudgetPercentage = false
				this.setBudgetInAccountBudgetPercentage(this.campaign.budget)
			},

			setBudget(budget){
				this.budget = budget
				this.campaign.budget = this.budget

			},

			clearBudget() {
				this.budget = null
				this.budgetPercentage = null
			},

			showModal(modalComponent) {
	      const element = document.createElement("div")
	      document.body.appendChild(element)
	      const view = createApp(modalComponent, {
	      	onHide: (e) => element.remove(),
	      	campaign: this.campaign,
	      }).mount(element)
      },

			openExpensesModal(){
				this.showModal(ExpensesModal)
			}
		},

		mounted() {
			if(this.campaign.budget) {
				this.setBudgetInAccountBudgetPercentage(this.campaign.budget)
			}
		},

		watch: {
			budget: function(newValue, oldValue) {
				const difference = newValue - (oldValue || 0)

				if(difference && difference !== 0) {
					this.$emit("budgetDifference", difference)
				}
			},
		}
	}
</script>
<template>
	<td>
		<span
			@click="$emit('pinCampaign', campaign)"
			:class="pinned ? 'text-danger' : ''"
			class="cursor-pointer">
			<i class="bi bi-pin"></i>
		</span>
	</td>
	<td style="max-width: 150px">
		{{ campaign.name }}
	</td>
	<td class="p-1">
		<span
			:class="'text-bg-' + campaignBadgeStatusColor"
			class="badge rounded-pill p-1"
		>
			{{ campaign.status.toLowerCase() }}
		</span>
	</td>
	<td>
		<div class="d-flex">
			<div
				v-if="editBudget"
				class="input-group input-group-sm position-relative"
			>
			  <span class="input-group-text" for="budget">
			  	{{ campaign.currency_code }}
			  </span>
			  <input
			  	v-model="budget"
			  	@keyup.enter="saveBudget()"
			  	type="number"
			  	class="form-control"
			  	id="budget"
			  >
			  <span
			  	v-if="budget !== null"
			  	@click="clearBudget()"
			  	class="input-group--clear">
			  	<i class="bi bi-x-circle-fill"></i>
			  </span>
			  <button
			  	@click="saveBudget()"
			  	class="btn btn-primary btn-sm"
			  >
			  	<i class="bi bi-check-lg"></i>
				</button>
				<button
					@click="closeBudgetEdition()"
					class="btn btn-light btn-sm"
				>
					<i class="bi bi-x"></i>
				</button>
			</div>
			<div v-else>
				<template v-if="budget">
					{{ formatNumberWithCurrency(budget, campaign.currency_code) }}
				</template>
				<template v-else>
					No budget
				</template>
				<span
					@click.stop="editBudget = true"
					class="cursor-pointer ms-2"
				>
					<i class="bi bi-pen"></i>
				</span>
			</div>
		</div>
	</td>
	<td>
		<div
			v-if="editBudgetPercentage"
			class="input-group input-group-sm">
			<span class="input-group-text">%</span>
			<input
				v-model="budgetPercentage"
				@keyup.enter="saveBudgetPercentage()"
		  	type="number"
		  	class="form-control"
		  	min="0"
		  >
		  <button
		  	@click.stop="saveBudgetPercentage()"
		  	class="btn btn-primary btn-sm"
		  >
		  	<i class="bi bi-check-lg"></i>
			</button>
			<button
				@click.stop="closeBudgetPercentageEdition()"
				class="btn btn-light btn-sm"
			>
				<i class="bi bi-x"></i>
			</button>
	  </div>
	  <div v-else>
	  	<template v-if="budgetPercentage">
				{{ budgetPercentage + "%" }}
			</template>
			<template v-else>
				-
			</template>
			<span
				@click.stop="editBudgetPercentage = true"
				class="cursor-pointer ms-2"
			>
				<i class="bi bi-pen"></i>
			</span>
	  </div>
	</td>
	<td>
		{{ formatNumberWithCurrency(campaign.cost, campaign.currency_code) }}
	</td>
	<td>
		{{
			forecastEndOfMonth() ?
				formatNumberWithCurrency(forecastEndOfMonth(), campaign.currency_code) : "-"
			}}
	</td>
	<td>
		<template v-if="budget">
			{{ formatNumberWithCurrency(insight(), campaign.currency_code) }}
		</template>
		<template v-else>-</template>
	</td>
	<td class="p-1">
		<button
			@click.stop="openExpensesModal()"
			class="btn btn-primary btn-xs me-1">
			Expenses / day
		</button>
		<button class="btn btn-primary btn-xs disabled">
			Adjust budget
		</button>
	</td>
</template>