<script>
	import axios from "axios"

	export default {
		name: "SelectableAccountsModal",

		props: {
			accountsIdsToDisable: {
				type: Array,
				default: []
			},
			onlyWithPmaxCampaigns: {
				type: Boolean,
				default: false,
				required: false
			}
		},

		data() {
			return {
				selectedAccounts: [],
				accounts: [],
				isFetched: false,
				checkedAccountsToAdd: []
			}
		},

		methods: {
			fetchGoogleAdsAccounts() {
	  		axios.get("/api/google/ads_accounts", {
					params: {
						only_with_pmax_campaigns: this.onlyWithPmaxCampaigns
					}
				})
	  			.then(function (response) {
	  				if(response.status == 200) {
	  					this.accounts = response.data.google_ads_accounts
	  					this.isFetched = true
	  				}
	  			}.bind(this))
	  			.catch(function(error){
	  				this.error = error
	  			}.bind(this))
	  	},

	  	toggleAccount(account) {
	  		if(this.checkedAccountsToAdd.find(acc => acc.id === account.id)) {
	  			this.checkedAccountsToAdd = this.checkedAccountsToAdd.filter((acc) => acc.id != account.id)
	  		} else {
	  			this.checkedAccountsToAdd.push(account)
	  		}
	  	},

	  	addAccounts() {
	  		this.$emit('add-accounts', this.checkedAccountsToAdd)
	  		this.modal.hide()
	  	},

			availableAccount(account) {
				return !this.accountsIdsToDisable.includes(account.id)
			},

			toggleAll() {
				this.checkedAccountsToAdd = this.accounts
			},

			allAccountsSelected() {
				return JSON.stringify(this.accountsIdsToDisable.sort()) === JSON.stringify(this.accounts.map(acc => acc.id).sort())

			}
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})

			this.fetchGoogleAdsAccounts()
		}
	}
</script>

<template>
	<div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h1 class="modal-title fs-5" id="staticBackdropLabel">
	        	Google Ads accounts
	        </h1>
	        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	      </div>
	      <div class="modal-body">
	      	<template v-if="isFetched">
	      		<template v-if="accounts.length > 0">
	      			<div class="form-check">
	      				<input
	      					@click="toggleAll()"
	      					:disabled="allAccountsSelected()"
									type="checkbox"
									class="form-check-input"
								>
								<label
									class="form-check-label mb-2 fw-semibold"
								>
									Select all
								</label>
	      			</div>
	      			<div
	      				v-for="account in accounts"
	      				class="form-check"
	      			>
	      				<input
									type="checkbox"
									:id="account.id"
									:value="account"
									:checked="checkedAccountsToAdd.includes(account) && availableAccount(account)"
									@click="toggleAccount(account)"
									:disabled="!availableAccount(account)"
									class="form-check-input"
								>
								<label
									:for="account.id"
									class="form-check-label"
								>
									{{ account.name }}
								</label>
	      			</div>
	      		</template>
	      		<div
							v-else
							class="text-center text-secondary"
						>
							No accounts
						</div>
	      	</template>
	      	<div
						v-else
						class="text-center text-secondary"
					>
						<div class="spinner-border" role="status">
							<span class="visually-hidden"></span>
						</div>
						Fetching your accounts
					</div>
	      </div>
	      <div class="modal-footer">
	        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
	        <button
					:disabled="checkedAccountsToAdd.length == 0"
					@click="addAccounts()"
					type="button"
					class="btn btn-primary"
				>
					Add accounts
				</button>
	      </div>
	    </div>
	  </div>
	</div>
</template>
