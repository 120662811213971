import GoogleAdsLogo from '../../assets/images/fallback/google_ads_logo.png';
import GoogleAdsLogoDark from '../../assets/images/google_ads_logo_dark.png';
import MetaLogoDark from '../../assets/images/meta_logo_dark.png';
import AdverteLogo from '../../assets/images/adverte.png';

export default {
	defaultGoogleAdsLogo() {
		return GoogleAdsLogo
	},

	defaultGoogleAdsLogoDark() {
		return GoogleAdsLogoDark
	},

	metaLogoDark() {
		return MetaLogoDark
	},

	defaultAdverteLogo() {
		return AdverteLogo
	}
}
