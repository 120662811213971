<script>
	import axios from "axios"

	export default {
		name: "DisplayCleanerUrlsToKeepModal",
		props: {
			exclusionListUrlsToKeep: {
				type: Array,
				required: true,

			}
		},

		data() {
			return {
				url: null,
				urls: []
			}
		},

		methods: {
			addUrl() {
				console.log(this.url)
				this.urls.push(this.url)
				this.url = null
			},

			removeUrl(url) {
				this.urls = this.urls.filter(existingUrls => existingUrls !== url)
			},

			saveUrlsToKeep() {
				this.$emit("saveUrlsToKeep", this.urls)
			}
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})

			this.urls = this.exclusionListUrlsToKeep
		}
	}
</script>
<template>
	<div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h1 class="modal-title fs-5" id="staticBackdropLabel">Domain names to keep</h1>
	        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	      </div>
	      <div class="modal-body">
	        <div class="p-2 bg-light rounded mb-3">
	        	Adding urls will prevent them to be removed on list even if they are reaching the CTR max defined.
	        </div>
	        <ul class="list-unstyled">
	        	<li v-for="url in urls" class="mb-2">
	        		{{ url }}
	        		<button
	        			@click="removeUrl(url)"
	        			class="btn btn-danger btn-xs float-end"
	        		>
	        			Remove
	        		</button>
	        	</li>
	        </ul>
	        <div class="input-group mb-3">
					  <input
					  	v-model="url"
					  	type="text" class="form-control"
					  	placeholder="google.com"
					  >
					  <button
					  	@click="addUrl()"
					  	class="btn btn-primary"
					  	:class="{ disabled: !url }"
					  	type="button"
					  >
					  	Add
						</button>
					</div>

	      </div>
	      <div class="modal-footer">
	        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
	        <button
	        	@click="saveUrlsToKeep"
	        	type="button"
	        	class="btn btn-primary"
	        	data-bs-dismiss="modal"
	        >Save</button>
	      </div>
	    </div>
	  </div>
	</div>
</template>