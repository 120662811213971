<script>
	export default {
		name: "AccountHierarchyElement",

		props: {
			account: {
				type: Object,
				default: {}
			},
			accountsToDisable: {
				type: Array,
				default: []
			}
		},

		data() {
			return {
				selectedAccounts: [],
			}
		},

		methods: {
			toggleAccount(account) {
				this.$emit("toggleAccount", account)
			},

			availableAccount() {
				return !this.accountsToDisable.map((accountToDisable) => {
					return accountToDisable.account_customer_id.split("/")[1]
				}).includes(this.account.description.id.toString())
			},

			accountStyle() {
				if(this.account.depth == 0) {
					return { "margin-bottom": "10px"}
				}

				let multiplier

				if(this.account.manager) {
					multiplier = 10
				} else {
					multiplier = 12
				}

				return {
					"margin-left": `${this.account.depth * 10}px`,
					"margin-bottom": "10px"
				}
			},
		}
	}
</script>

<template>
	<div>
		<div
			v-if="account.description.manager"
			:style="accountStyle()"
			class="fw-bold"
		>
			Manager Account: {{ account.description.descriptive_name }}
		</div>
		<div
			v-else
			:style="accountStyle()"
			class="form-check"
		>
			<input
				type="checkbox"
				:id="account.description.id"
				:value="account.description.id"
				:disabled="!availableAccount()"
				@click="$emit('toggleAccount', account)"
				class="form-check-input"
			>
			<label
				:for="account.description.id"
				class="form-check-label"
			>
				{{ account.description.descriptive_name }}
			</label>
		</div>
		<template v-if="Object.keys(account.children).length > 0">
			<template
				v-for="(value, propertyName) in account.children"
				:key="propertyName"
			>
				<AccountHierarchyElement :account="value" :accounts-to-disable="accountsToDisable" @toggle-account="toggleAccount" />
			</template>
		</template>
	</div>
</template>
