<script>
	import axios from "axios"
	import { createApp } from 'vue/dist/vue.esm-bundler';

	//Vues
	import SelectableAccountsModal from "@/views/google/selectable_accounts_modal.vue"
	import DisplayCleanerAdsAccount from "@/views/google/display_cleaner_account.vue"
	import DisplayCleanerUrlsToKeepModal from "@/views/google/display_cleaner_urls_to_keep_modal.vue"
	import DisplayCleanerDaysSelectorModal from "@/views/google/display_cleaner_days_selector_modal.vue"

	export default {
		components: { DisplayCleanerAdsAccount },
		computed: {
			userSettingsId() {
				return Adverte.settings.id
			},
		},

		data() {
			return {
				exclusionListGoogleAdsAccountsIds: [],
				exclusionListMaxCtr: null,
				editExclusionListMaxCtr: false,
				exclusionListUrlsToKeep: [],
				exclusionListExcludeApps: false,
				exclusionListExcludeYoutube: false,
				exclusionListDaysOfMonthTrigger: []
			}
		},

		methods: {
	  	openMyModal() {
	  		const element = document.createElement("div")
	      document.body.appendChild(element)
	      const view = createApp(SelectableAccountsModal, {
	      	onHide: (e) => element.remove(),
	      	onAddAccounts: (accounts) => { this.addAccounts(accounts) },
	      	accountsIdsToDisable: this.exclusionListGoogleAdsAccountsIds
	      }).mount(element)
			},

			openUrlsToKeepModal() {
				const element = document.createElement("div")
	      document.body.appendChild(element)
	      const view = createApp(DisplayCleanerUrlsToKeepModal, {
	      	onHide: (e) => element.remove(),
	      	onSaveUrlsToKeep: (e) => {
	      		this.exclusionListUrlsToKeep = e
	      		this.saveUserSettings()
	      	},
	      	exclusionListUrlsToKeep: this.exclusionListUrlsToKeep

	      }).mount(element)
			},

			openDaysToRunSciptModal() {
				const element = document.createElement("div")
	      document.body.appendChild(element)
	      const view = createApp(DisplayCleanerDaysSelectorModal, {
	      	onHide: (e) => element.remove(),
	      	onSaveDays: (e) => {
	      		this.exclusionListDaysOfMonthTrigger = e
	      		this.saveUserSettings()
	      	},
	      	exclusionListDaysOfMonthTrigger: this.exclusionListDaysOfMonthTrigger

	      }).mount(element)
			},

      addAccounts(accounts) {
      	accounts.forEach((account) => {
					if(!this.exclusionListGoogleAdsAccountsIds.includes(account.id)) {
						this.exclusionListGoogleAdsAccountsIds.push(account.id)
					}
				})
      	this.saveUserSettings()
			},

			saveUserSettings() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken

				axios.patch(`/api/user/settings/${this.userSettingsId}`, {
					exclusion_list_google_ads_accounts_ids: this.exclusionListGoogleAdsAccountsIds,
					exclusion_list_max_ctr: this.exclusionListMaxCtr,
					exclusion_list_urls_to_keep: this.exclusionListUrlsToKeep,
					exclusion_list_exclude_apps: this.exclusionListExcludeApps,
					exclusion_list_days_of_month_trigger: this.exclusionListDaysOfMonthTrigger,
					exclusion_list_exclude_youtube: this.exclusionListExcludeYoutube,
				})
					.then(function (response) {
						if(response.status == 200) {
							if(this.editExclusionListMaxCtr) {
								this.editExclusionListMaxCtr = false
							}
							Adverte.settings.exclusion_list_google_ads_accounts_ids = this.exclusionListGoogleAdsAccountsIds
							Adverte.settings.exclusion_list_max_ctr = this.exclusionListMaxCtr
							Adverte.settings.exclusion_list_urls_to_keep = this.exclusionListUrlsToKeep
							Adverte.settings.exclusion_list_exclude_apps = this.exclusionListExcludeApps
							Adverte.settings.exclusion_list_days_of_month_trigger = this.exclusionListDaysOfMonthTrigger
							Adverte.settings.exclusion_list_exclude_youtube = this.exclusionListExcludeYoutube
						}
					}.bind(this))
			},

			removeAccount(accountId) {
				this.exclusionListGoogleAdsAccountsIds = this.exclusionListGoogleAdsAccountsIds.filter((id) => {
					return id !== accountId
				})

				this.saveUserSettings()
			}
		},

		mounted() {
			this.exclusionListGoogleAdsAccountsIds = Adverte.settings.exclusion_list_google_ads_accounts_ids
			this.exclusionListMaxCtr = Adverte.settings.exclusion_list_max_ctr
			this.exclusionListUrlsToKeep = Adverte.settings.exclusion_list_urls_to_keep
			this.exclusionListExcludeApps = Adverte.settings.exclusion_list_exclude_apps
			this.exclusionListExcludeYoutube = Adverte.settings.exclusion_list_exclude_youtube
			this.exclusionListDaysOfMonthTrigger = Adverte.settings.exclusion_list_days_of_month_trigger
		},

		watch: {
			exclusionListExcludeApps() {
				this.saveUserSettings()
			},
			exclusionListExcludeYoutube() {
				this.saveUserSettings()
			}
		}
	}
</script>
<template>
	<div class="container my-5">
		<h2 class="mb-1">Display cleaner</h2>
		<p class="mb-3">Create lists on campaign display to exclude domain names based on CTR</p>
		<div class="d-flex justify-content-between align-items-center mb-3 p-2">
			<div>
				<span class="fw-semibold me-2">
					Exclusion list CTR max to exclude:
				</span>
				<span
					v-if="editExclusionListMaxCtr"
					class="input-group input-group-sm">
					<span class="input-group-text">%</span>
					<input
						v-model="exclusionListMaxCtr"
						@keyup.enter="saveUserSettings()"
						type="number"
						class="form-control"
						min="0"
						max="100"
					>
					<button
						@click.stop="saveUserSettings()"
						class="btn btn-primary btn-sm"
					>
						<i class="bi bi-check-lg"></i>
					</button>
					<button
						@click.stop="editExclusionListMaxCtr = false"
						class="btn btn-light btn-sm"
					>
						<i class="bi bi-x"></i>
					</button>
				</span>
				<span v-else>
					<template v-if="exclusionListMaxCtr">
						{{ exclusionListMaxCtr + "%" }}
					</template>
					<template v-else>
						-
					</template>
					<span
						@click.stop="editExclusionListMaxCtr = true"
						class="cursor-pointer ms-2"
					>
						<i class="bi bi-pen"></i>
					</span>
				</span>
				<span
					class="fw-semibold me-2"
					title="Domain names even if the CTR is more than the one specified"
				>
					Domain names to never exclude:
					<span
						@click.stop="openUrlsToKeepModal()"
						class="cursor-pointer ms-2">
						<i class="bi bi-pen"></i>
					</span>
				</span>
				<span class="fw-semibold me-2">
						Month days to run script:
						<span

							@click.stop="openDaysToRunSciptModal()"
							class="cursor-pointer ms-2">
							<i class="bi bi-pen"></i>
						</span>
				</span>
				<div class="mt-2">
					<span class="form-check form-check-inline">
						<label class="form-check-label fw-semibold me-2">
							Exclude placements in apps
						</label>
						<input class="form-check-input" type="checkbox" v-model="exclusionListExcludeApps">
					</span>
					<span class="form-check form-check-inline">
						<label class="form-check-label fw-semibold me-2">
							Exclude youtube channels
						</label>
						<input class="form-check-input" type="checkbox" v-model="exclusionListExcludeYoutube">
					</span>
				</div>
			</div>
			<button
				@click="openMyModal()"
				class="btn btn-primary"
			>
				Ads accounts
			</button>
		</div>
		<div class="row">
			<template v-if="exclusionListGoogleAdsAccountsIds.length > 0">
				<div class="mb-3 col-12">
					<div
						v-for="exclusionListGoogleAdsAccountsId in exclusionListGoogleAdsAccountsIds"
						:key="exclusionListGoogleAdsAccountsId"
						class="col-12 col-md-6 mb-5"
					>
						<DisplayCleanerAdsAccount
							:accountId="exclusionListGoogleAdsAccountsId"
							:excludeApps="exclusionListExcludeApps"
							:excludeYoutube="exclusionListExcludeYoutube"
							@remove-account="removeAccount">
						</DisplayCleanerAdsAccount>
					</div>
				</div>
			</template>
			<div v-else class="col-12 text-center py-5">
				You have no accounts selected.
			</div>
		</div>
	</div>
</template>