<script>
	import axios from "axios"
	import moment from "moment"

	export default {
		props: {
			accountId: {
				type: Number,
				required: true
			},
			excludeYoutube: {
				type: Boolean,
				required: true
			},
			excludeApps: {
				type: Boolean,
				required: true
			},
		},

		data() {
			return {
				account: null,
				isFetched: false,
				exclusionListFetched: false,
				exclusionList: null
			}
		},

		methods: {
			fetchGoogleAdsAccounts() {
	  		axios.get(`/api/google/ads_accounts/${this.accountId}`)
	  			.then(function (response) {
	  				if(response.status == 200) {
	  					this.account = response.data
	  					this.isFetched = true
	  					this.fetchExclusionList()
	  				}
	  			}.bind(this))
	  	},

	  	fetchExclusionList() {
	  		axios.get(`/api/google/ads_accounts/${this.accountId}/ads_accounts/exclusion_lists`)
	  			.then(function (response) {
	  				if(response.status == 200) {
	  					this.exclusionListFetched = true
	  					this.exclusionList = response.data
	  				}
	  			}.bind(this))
	  	},

	  	removeAccount() {
	  		this.$emit('removeAccount', this.accountId)
	  	},

			excludedUrlsCount()	{
				let excludedUrls = this.exclusionList.excluded_urls
				if(!this.excludeApps && !this.excludeYoutube) {
					excludedUrls = excludedUrls.filter((url) => {
						return !url.includes('youtube') && !url.includes("play.google") && !url.includes("itunes.apple")
					})
				} else if(this.excludeApps && !this.excludeYoutube) {
					excludedUrls = excludedUrls.filter(url => !url.includes('youtube'))
				} else if(!this.excludeApps && this.excludeYoutube) {
					excludedUrls = excludedUrls.filter((url) => {
						return !url.includes('play.google') && !url.includes('itunes.apple')
					});
				}

				return excludedUrls.length
			},

			excludedUrlUpdatedInfo() {
				const excludedUrlsUpdatedAt = this.exclusionList.excluded_urls_updated_at
				if(excludedUrlsUpdatedAt) {
					return moment(excludedUrlsUpdatedAt).format("MMM Do YYYY")
				} else {
					return "-"
				}
			}
		},

		mounted() {
			this.fetchGoogleAdsAccounts()
		}
	}
</script>

<template>
	<div class="card">
		<div v-if="isFetched">
			<div class="card-header">
				<div
					@click="removeAccount()"
					class="btn-close float-end"></div>
				<h3 class="mb-0">{{ account.name }}</h3>
			</div>
			<div class="card-body">
				<template v-if="exclusionList">
					<span class="text-muted me-2">
						Exclusion list name:
					</span>
					<span class="fw-semibold">
						{{ exclusionList.name }}
					</span>
					<span class="text-muted me-2 ms-3">
							Exclusion list last applied on:
					</span>
					<span class="fw-semibold">
						{{ excludedUrlsCount() }} urls
					</span>
					<span class="text-muted me-2">
							Exclusion list last update:
					</span>
					<span class="fw-semibold">
						{{ excludedUrlUpdatedInfo()}}
					</span>
				</template>
				<template v-else>
					No exclusion list
				</template>
			</div>
		</div>
	</div>
</template>