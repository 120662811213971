<script>
	// Helpers
	import ImagesHelper from "@/helpers/images_helper"

	export default {
		methods: {
			adverteLogo() {
				return ImagesHelper.defaultAdverteLogo()
			},
		}
	}
</script>

<template>
	<nav class="navbar navbar-expand-lg bg-white d-xl-none fixed-top">
	  <div class="container-fluid">
	    <a class="navbar-brand" href="/">
	    	<img :src="adverteLogo()" style="height: 60px">
	    </a>

	    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
	      <div class="navbar-nav">
	        <a class="nav-link active" aria-current="page" href="/">Google Accounts</a>
	      </div>
	    </div>
	  </div>
	</nav>
</template>