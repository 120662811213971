<script>
	import axios from "axios"
	import datesHelper from "@/helpers/dates_helper"

	//Components
	import Campaign from "@/views/google/ads_account/campaign.vue"
	import Pagination from "@/views/pagination.vue"

	export default {
		components: {
			Campaign,
			Pagination
		},

		props: {
			id: {
				type: [Number, String],
				required: true
			}
		},

		computed: {
			userSettingsId() {
				return Adverte.settings.id
			}
		},

		data: () => ({
			account: null,
			accountBudget: null,
			campaigns: [],
			isFetched: false,
			lastSevenDaysSpent: [],
			lastSevenDaysFetched: false,
			pinnedCampaigns: [],
			pinnedCampaignsFetched: false,
			onlyDisplayActiveCampaigns: true,
			totalBudget: 0,
			campaignsQuery: null,
			editAccountBudget: false,
			pagination: {
				currentPage: 1,
				totalPages: 1
			}
		}),

		methods: {
			fetchCampaigns() {
				axios.get(
					`/api/google/ads_accounts/${this.id}/ads_accounts/campaigns`,
					{ params: {
							page: this.pagination.currentPage,
							only_display_active: this.onlyDisplayActiveCampaigns,
							search: this.campaignsQuery,
							exclude_pinned_campaigns: true,
						}
					}
				)
					.then(function (response) {
						if(response.status === 200) {
							this.campaigns = response.data.campaigns
							this.totalBudget = response.data.total_budget
							this.account = response.data.ads_account
							this.accountBudget = response.data.ads_account.budget
							this.pagination.totalPages = response.data.pagination.total_pages
							this.pagination.currentPage = response.data.pagination.current_page
							this.isFetched = true
						}
					}.bind(this))
					.catch(function (error) {
						console.log(error)
					})
			},

			fetchPinnedCampaigns() {
				axios.get(
					`/api/google/ads_accounts/${this.id}/ads_accounts/campaigns`,
					{ params: {
							only_pinned_campaigns: true
						}
					}
				)
				.then(function (response) {
						if(response.status === 200) {
							this.pinnedCampaigns = response.data.campaigns
							this.totalBudget = response.data.total_budget
							this.account = response.data.ads_account
							this.accountBudget = response.data.ads_account.budget
							this.pinnedCampaignsFetched = true
						}
				}.bind(this))
			},

			fetchLastSevenDaysSpent() {
				axios.get(`/api/google/ads/${this.id}`, {
					params: { last_seven_days: true }
				})
				.then(function (response) {
					this.lastSevenDaysSpent = response.data.campaigns.map(campaign => {
						return {
							cost: campaign.metrics.cost_micros,
							googleId: campaign.google_id
						}
					})
					this.setLastSevenDaysSpentPerCampaign()
					this.lastSevenDaysFetched = true
				}.bind(this))
			},

			setLastSevenDaysSpentPerCampaign() {
				this.campaigns.concat(this.pinnedCampaigns).forEach((campaign) => {
					const campaignSpent = this.lastSevenDaysSpent.find((spent) => {
						return spent.googleId == campaign.google_id
					})

					campaign.last_seven_days_spent = Math.round(campaignSpent?.cost || 0)
				})
			},

			updateTotalBudgetBy(number) {
				this.totalBudget += number
			},

			pinCampaign(campaign) {
				const pinned = this.pinnedCampaigns.find(c => c.id == campaign.id )
				if(pinned) {
					this.pinnedCampaigns = this.pinnedCampaigns.filter(c => c.id != campaign.id)
					this.campaigns.push(pinned)
				} else {
					this.pinnedCampaigns.push(campaign)
					this.campaigns = this.campaigns.filter(c => c.id != campaign.id)
				}
				this.savePinnedCampaign()
			},

			savePinnedCampaign() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken

				axios.patch(`/api/user/settings/${this.userSettingsId}`, {
					pinned_campaigns_ids: this.pinnedCampaigns.map(c => c.id)
				})
					.then(function (response) {
						if(response.status == 200) {
							console.log("saved")
						}
					}.bind(this))
					.catch(function (error) {
						console.log(error)
					})
			},

			changeCurrentPage(event) {
				this.pagination.currentPage = event
				this.fetchCampaigns()
			},

			debounce(callback, wait) {
		    let timeout;
		    return (...args) => {
		        const context = this;
		        clearTimeout(timeout);
		        timeout = setTimeout(() => callback.apply(context, args), wait);
		    };
			},

			saveAccountBudget() {
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
				axios.patch(`/api/google/ads_accounts/${this.account.id}`, {
					budget: this.accountBudget
				})
					.then(function (response) {
						if(response.data.success) {
							this.editAccountBudget = false
							this.account.budget = this.accountBudget
						} else {
							// TODO: to use catch
							console.log("error")
						}
					}.bind(this))
			},

			closeBudgetEdition() {
				this.editAccountBudget = false
				this.accountBudget = this.account.budget
			},

			clearBudget() {
				this.accountBudget = null
			},

			formatNumberWithCurrency(amount, currency) {
				if(!currency) {
					return amount
				} else {
					return new Intl.NumberFormat("fr-FR",
						{ style: "currency", currency: currency, minimumFractionDigits: 0 }
					).format(amount)
				}
			},
		},

		created() {
			this.debouncedFetchCampaigns = this.debounce(
        this.fetchCampaigns,
        500
      )
		},

		mounted() {
			this.fetchLastSevenDaysSpent()
			this.debouncedFetchCampaigns()
			this.fetchPinnedCampaigns()
		},

		watch: {
			onlyDisplayActiveCampaigns: function() {
				this.isFetched = false
				this.debouncedFetchCampaigns()
			},
			campaignsQuery: function() {
				this.isFetched = false
				this.debouncedFetchCampaigns()
			},
			isFetched: function(newValue, _) {
				if(newValue) {
					this.setLastSevenDaysSpentPerCampaign()
				}
			},
			pinnedCampaignsFetched: function(newValue, _) {
				if(newValue) {
					this.setLastSevenDaysSpentPerCampaign()
				}
			},
		},
	}
</script>

<template>
	<div class="my-5">
		<template v-if="isFetched || pinnedCampaignsFetched">
			<h2>
				{{ account.name}}
			</h2>
			<div class="row">
				<div class="col-6">
					<div class="card">
					  <div class="card-body">
					    <h3 class="card-title">Campaign list</h3>
					    <p class="card-text">
					    	Forecast are based on the last 7 days' trend.
					    </p>
					    <p>
					    	You can define a budget / campaign, an apply the correction to adapt the campaign's spent to achieve your goal.
					    </p>
					    <h5 class="mb-2">Account Budget</h5>
					    <span class="text-muted me-2 mb-2">
								<small>Monthly budget:</small>
							</span>
							<span v-if="editAccountBudget">
								<span class="input-group input-group-sm">
								  <span class="input-group-text" id="inputGroup-sizing-sm">
								  	{{ (campaigns[0] || pinnedCampaigns[0]).currency_code }}
								  </span>
								  <input
								  	v-model="accountBudget"
								  	@keyup.enter="saveAccountBudget"
								  	type="number"
								  	class="form-control"
								  	aria-label="1000"
								  	aria-describedby="inputGroup-sizing-sm"
								  >
								  <span
								  	v-if="accountBudget !== null"
								  	@click="clearBudget()"
								  	class="input-group--clear">
								  	<i class="bi bi-x-circle-fill"></i>
								  </span>
								  <button
								  	@click="saveAccountBudget()"
								  	class="btn btn-primary btn-sm"
								  >
								  	<i class="bi bi-check-lg"></i>
									</button>
									<button
										@click="closeBudgetEdition()"
										class="btn btn-light btn-sm"
									>
										<i class="bi bi-x"></i>
									</button>
								</span>
							</span>
							<span v-else>
								<template v-if="account.budget">
									{{ formatNumberWithCurrency(account.budget, (campaigns[0] || pinnedCampaigns[0])?.currency_code) }}
								</template>
								<template v-else>
									No budget
								</template>
								<span
									@click="editAccountBudget = true"
									class="cursor-pointer ms-2"
								>
									<i class="bi bi-pen"></i>
								</span>
							</span>
					  </div>
					</div>
				</div>
			</div>
		</template>
	</div>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header d-flex align-items-center py-1">
				  <div class="p-2 flex-grow-1"/>
				  <div class="p-2 form-check">
					  <input
					  	v-model="onlyDisplayActiveCampaigns"
					  	class="form-check-input"
					  	type="checkbox"
					  >
					  <label class="form-check-label">
					    Display only active campaigns
					  </label>
				  </div>
			    <div class="p-2">
			      <input
			      	v-model="campaignsQuery"
			      	type="text"
			      	class="form-control"
			      	placeholder="Campaign name"
			      >
			    </div>
				</div>
				<div class="table-responsive border-top">
					<table class="table w-100">
						<thead>
							<tr>
								<th class="p-3"></th>
								<th class="p-3 fw-bold">Campaign</th>
								<th class="p-3 fw-bold">status</th>
								<th class="p-3 fw-bold">Monthly budget</th>
								<th class="p-3 fw-bold">
									Total budget %
									<i
										class="bi bi-info-circle"
										title="Account budget should be filled"
									></i>
								</th>
								<th class="p-3 fw-bold">Spent</th>
								<th class="p-3 fw-bold">Forecast End Of Month</th>
								<th class="p-3 fw-bold">🎯 Insight</th>
								<th class="p-3 fw-bold">Action</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="pinnedCampaignsFetched && pinnedCampaigns.length > 0">
								<tr v-for="campaign in pinnedCampaigns" :key="campaign.id + campaign.name">
									<Campaign
										:campaign="campaign"
										:adAccountBudget="account.budget"
										:pinned="true"
										@pin-campaign="pinCampaign"
										@budget-difference="updateTotalBudgetBy"
									/>
								</tr>
							</template>
							<template v-if="isFetched && lastSevenDaysFetched">
								<template v-if="campaigns.length > 0">
									<tr v-for="campaign in campaigns" :key="campaign.id + campaign.name">
										<Campaign
											:campaign="campaign"
											:ad-account-budget="account.budget"
											@pin-campaign="pinCampaign"
											@budget-difference="updateTotalBudgetBy"
										/>
									</tr>
									<tr>
										<td></td>
										<td class="fw-bold text-uppercase">total</td>
										<td></td>
										<td class="fw-bold">
											{{ totalBudget }}
											{{ accountBudget ? " / " + accountBudget : "" }}
										</td>
										<td class="fw-bold">
											{{ accountBudget ? ((totalBudget / accountBudget) * 100).toFixed(1) + " %" : "" }}
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</template>
								<tr v-else class="text-center text-secondary">
									<td colspan="10" class="py-3">
										No campaign found
									</td>
									<td></td>
								</tr>
							</template>
							<tr v-else class="text-center text-secondary">
								<td colspan="10" class="py-3">
									<div class="spinner-border" role="status">
										<span class="visually-hidden"></span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<Pagination
					:totalPages="pagination.totalPages"
					:currentPage="pagination.currentPage"
					:isFetched="isFetched"
					@change-current-page="changeCurrentPage"
				/>
			</div>
		</div>
	</div>
</template>
