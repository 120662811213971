<script>
	import axios from "axios"
	import ImagesHelper from "@/helpers/images_helper"
	import DatesHelper from "@/helpers/dates_helper"
	import { createApp } from 'vue/dist/vue.esm-bundler';

	// Components
	import AdsAccount from "@/views/google/ads_account.vue"
	import AccountHierarchyElement from "@/views/google/account_hierarchy_element.vue"

	//Vues
	import AccountHierarchyModal from "@/views/google/account_hierarchy_modal.vue"

	const MAX_UNPAID_ACCOUNTS = 1

	export default {
		components: {
			AdsAccount,
			AccountHierarchyElement
		},

		data() {
    	return {
				hasAccountToken: false,
				connectedAccountEmail: null,
				apiError: false,
    		accountsHierarchy: [],
    		selectableAccountsIds: [],
    		accountHierarchyNeverFetched: true,
    		accountsHierarchyFetched: false,
    		accounts: [],
    		checkedAccountsToAdd: [],
    		accountsFetched: false,
				error: null,
				search: null,
				invoicePaid: false,
				budgetReachedAlerts: false,
    	}
  	},

		methods: {
			saveUserSettings() {
				const userSettingsId = Adverte.settings.id
				const csfrToken = document.querySelector('[name=csrf-token]').content
				axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken

				axios.patch(`/api/user/settings/${userSettingsId}`, {
					accounts_budget_reached_alert: this.budgetReachedAlerts
				}).then(function(response) {
					if(response.status === 200) {
						Adverte.settings.accounts_budget_reached_alert = this.budgetReachedAlerts
					}
				}.bind(this))
			},

			fetchUserInfos() {
				axios.get("/session/google/user_info")
					.then(function (response) {
						if(response.data.error == "no token") { return }
						if(response.data.email) {
							this.hasAccountToken = true
							this.connectedAccountEmail = response.data.email
							this.fetchSelectedAccounts()
							this.fetchActiveSessionTokens()
						}
					}.bind(this))
					.catch(function (error) {
						if(error.response.data.error == "wrong google cookie") {
							window.location.assign("/session/google/login?use_other_account=true")
						}
					}.bind(this))
			},

			fetchSelectedAccounts() {
	  		axios.get("/api/google/ads_accounts", {
	  			params: {
	  				search: this.search
	  			}
	  		})
	  			.then(function (response) {
	  				if(response.status == 200) {
	  					this.accounts = response.data.google_ads_accounts
							this.accountsFetched = true
	  				}
	  			}.bind(this))
	  			.catch(function(error){
	  				this.error = error
	  			}.bind(this))
	  	},

			fetchActiveSessionTokens() {
				axios.get("/api/session_tokens")
					.then(function(response) {
						if(response.status == 200) {
							this.buildAccountHierarchy(response.data.session_tokens)
						}
					}.bind(this))
			},

			buildAccountHierarchy(sessionTokens) {
	  		const activeSessionToken = sessionTokens.find((sessionToken) => {
					return sessionToken.provider === "google" && sessionToken.active
				})

	  		this.accountHierarchyNeverFetched = !activeSessionToken.hierarchy_updated_at

				if (activeSessionToken && !!activeSessionToken.hierarchy_updated_at) {
					this.accountsHierarchy = activeSessionToken.hierarchy
				 	this.accountsHierarchyFetched = true
					this.setSelectableAccountsIds(activeSessionToken.hierarchy)
				} else if(activeSessionToken) {
					this.fetchActiveSessionTokenWithInterval(activeSessionToken)
				} else {
					// TODO: whant do to if no activeSessionToken ?
					console.log("no active session token")
				}
	  	},

	  	setSelectableAccountsIds(accounts) {
	  		Object.keys(accounts).forEach(function(key) {
	  			const account = accounts[key]
	  			if(account.description.manager) {
	  				if(Object.keys(account.children).length > 0) {
	  					this.setSelectableAccountsIds(account.children)
	  				}
	  			} else {
	  				this.selectableAccountsIds.push(account.description.client_customer)
	  			}
	  		}.bind(this))
	  	},

			fetchActiveSessionTokenWithInterval(activeSessionToken) {
				const timerStartAt = new Date()
				this.timer = setInterval(
					this.fetchActiveSessionToken, 2000, activeSessionToken, timerStartAt
				)
			},

			fetchActiveSessionToken(sessionToken, timerStartAt) {
				axios.get(`/api/session_tokens/${sessionToken.id}`)
					.then(function(response) {
						if(response.status == 200) {
							const hierarchyUpdatedAt = response.data.hierarchy_updated_at
							if(hierarchyUpdatedAt && (new Date(hierarchyUpdatedAt) >= timerStartAt)) {
								clearInterval(this.timer)
								this.accountsHierarchy = response.data.hierarchy
								this.accountsHierarchyFetched = true
								this.setSelectableAccountsIds(response.data.hierarchy)
							}
						}
					}.bind(this))
			},

	  	addAccounts(accounts) {
	  		this.checkedAccountsToAdd = accounts
	  		this.totalAccountsToAdd = this.checkedAccountsToAdd.length
	  		this.saveAccounts()
	  	},

	  	saveAccounts() {
	  		if (this.totalAccountsToAdd === 0) {
	  			this.checkedAccountsToAdd = []
	  			return
	  		}

  			const account = this.checkedAccountsToAdd.pop()
  			const csfrToken = document.querySelector('[name=csrf-token]').content
  			axios.defaults.headers.common['X-CSRF-TOKEN'] = csfrToken
  			axios.post("/api/google/ads_accounts", {
  				account_customer_id: account.description.client_customer,
  				name: account.description.descriptive_name,
  				resource_name: account.description.resource_name,
  				level: account.description.level,
  				account_email: this.connectedAccountEmail,
  				manager_customer_id: account.manager_customer_id
  			})
  				.then(function(response) {
  					if(response.status === 200) {
  						this.totalAccountsToAdd = this.totalAccountsToAdd - 1
	  					this.accounts.push(response.data.google_ads_account)
	  					this.saveAccounts()
  					}
  				}.bind(this))
  				.catch(function(error) {
  					this.error = error
  				}.bind(this))

	  	},

	  	removeAccount(account) {
	  		this.accounts = this.accounts.filter((googleAccount) => {
	  			return googleAccount.id !== account.id
	  		})
	  	},

	  	canSearch() {
	  		return this.invoicePaid || (this.accounts.length <= MAX_UNPAID_ACCOUNTS)
	  	},

	  	accountDispayable(index) {
	  		if(this.invoicePaid || (index + 1 <= MAX_UNPAID_ACCOUNTS)) {
	  			return true
	  		} else {
	  			return false
	  		}
	  	},

			allAvailableAccountsAdded() {
				return JSON.stringify(this.accounts.map(account => account.account_customer_id).sort()) ===
				JSON.stringify(this.selectableAccountsIds.sort())
			},

			defaultGoogleAdsLogo() {
				return ImagesHelper.defaultGoogleAdsLogo()
			},

			daysElapsedInMonth() {
				return DatesHelper.daysElapsed() + "/" + DatesHelper.monthsElapsed()
			},

			endOfMonth() {
				return DatesHelper.daysInMonth() + "/" + DatesHelper.monthsElapsed()
			},

			progressBarPercentage() {
				return {
					"width": `${(DatesHelper.daysElapsed() / DatesHelper.daysInMonth()) * 100}%`
				}
			},

			showModal(modalComponent, data) {
	      const element = document.createElement("div")
	      document.body.appendChild(element)
	      const view = createApp(modalComponent, {
	      	onHide: (e) => element.remove(),
	      	onAddAccounts: (accounts) => { this.addAccounts(accounts) },
	      	isFetched: this.accountsHierarchyFetched,
	      	accountsHierarchy: this.accountsHierarchy,
	      	accountHierarchyNeverFetched: this.accountHierarchyNeverFetched,
	      	accountsToDisable: this.accounts
	      }).mount(element)
      },

			openMyModal() {
				this.showModal(AccountHierarchyModal, {})
			},

			debounce(callback, wait) {
		    let timeout;
		    return (...args) => {
	        const context = this;
	        clearTimeout(timeout);
	        timeout = setTimeout(() => callback.apply(context, args), wait);
		    };
			},
		},

		created() {
			this.fetchUserInfos()
			this.debounceFetchSelectedAccounts = this.debounce(
        this.fetchSelectedAccounts,
        500
      )

      this.invoicePaid = Adverte.currentUser.invoice_paid
			this.budgetReachedAlerts = Adverte.settings.accounts_budget_reached_alert
		},

		watch: {
			search: function() {
				if(!this.canSearch()) { return }
				this.accountsFetched = false
				this.debounceFetchSelectedAccounts()
			},
			budgetReachedAlerts() {
				this.saveUserSettings()
			}
		}
	}
</script>

<template>
	<div class="container my-5">
		<h2 class="mb-5">🫶 Welcome on Adverte</h2>
		<template v-if="hasAccountToken">
			<p class="mb-3">
				You are connected on google with:
				<strong>{{ connectedAccountEmail }}</strong>
			</p>
			<div
				v-if="apiError"
				class="alert alert-danger"
			>
				<p>
					An error occured: use a google account which has access to Google Ads
				</p>
				<a class="btn btn-primary" aria-disabled="true" href="/session/google/login?use_other_account=true">
					<img :src="defaultGoogleAdsLogo()" style="height: 20px">
					Connect your Google Ads account
				</a>
			</div>
			<template v-else>
				<div class="d-flex justify-content-between align-items-center mb-3 p-2">
					<span class="form-check form-check-inline">
						<input
							v-model="budgetReachedAlerts"
							class="form-check-input"
							type="checkbox"
							id="budgetReachedAlerts"
						>
						<label class="form-check-label" for="budgetReachedAlerts">
							Receive alerts when budget reached
						</label>
					</span>
					<div class="d-flex justify-content-end mb-3 p-2">
						<div class="pe-2">
							<input
								v-model="search"
								:disabled="!canSearch()"
								type="text"
								class="form-control"
								placeholder="Account name"
							>
						</div>
						<button
							@click="openMyModal()"
							:disabled="allAvailableAccountsAdded() || !accountsHierarchyFetched"
							class="btn btn-primary"
						>
							<template v-if="accountsHierarchyFetched">
								Add accounts
							</template>
							<template v-else>
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="visually-hidden"></span>
								</div>
								Fetching Ads accounts
							</template>
						</button>
					</div>
				</div>
				<div class="row">
					<template v-if="accountsFetched">
						<template v-if="accounts.length > 0">
							<div class="mb-3 col-12">
								Today:
								<span class="fw-semibold pe-3">{{ daysElapsedInMonth() }}</span>
								End of Month:
								<span class="fw-semibold">{{ endOfMonth() }}</span>
								<div class="progress w-25">
								  <div class="progress-bar progress-bar-striped" :style="progressBarPercentage()"></div>
								</div>
							</div>
							<div
								v-for="(account, index) in accounts"
								:key="account.id"
								class="col-12 col-md-6 mb-5"
							>
								<AdsAccount
									:account="account"
									:displayable="accountDispayable(index)"
									@remove-account="removeAccount"/>
							</div>
						</template>
						<div v-else class="col-12 text-center py-5">
							You have no accounts selected.
						</div>
					</template>
					<div v-else class="col-12 py-5">
						<div class="d-flex justify-content-center text-secondary">
							<span class="me-2">Fetching</span>
							<div class="spinner-border" role="status">
								<span class="visually-hidden"></span>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<span class="pe-2">You do not have any google account connected</span>
			<a class="btn btn-primary"
				aria-disabled="true"
				href="/session/google/login"
			>
				<img :src="defaultGoogleAdsLogo()" style="height: 20px">
				Connect your Google Ads account
			</a>
		</template>
	</div>
</template>
