<script>
	export default {
		name: "DisplayCleanerDaySelectorModal",
		props: {
			exclusionListDaysOfMonthTrigger: {
				type: Array,
				required: true,

			}
		},

		data() {
			return {
				daysNumber: [],
				notValid: false
			}
		},

		methods: {
			saveDays() {
				this.$emit("saveDays", this.daysNumber.split(",").map((day) => parseInt(day)))
			}
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})
			if (this.exclusionListDaysOfMonthTrigger) {
				this.daysNumber = this.exclusionListDaysOfMonthTrigger.join(",")
			}
		},

		watch: {
			daysNumber: function(newValue, _) {
				if(newValue && typeof(newValue) == "string") {
					newValue.split(",").forEach((day) => {
						if(parseInt(day) < 1 || parseInt(day) > 30 || day == "") {
							this.notValid = true
						} else {
							this.notValid = false
						}
					})
				}
			}
		}
	}
</script>
<template>
	<div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h1 class="modal-title fs-5" id="staticBackdropLabel">Days in month scipt is triggered</h1>
	        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	      </div>
	      <div class="modal-body">
	        <div class="p-2 bg-light rounded mb-3">
	        	When days selected, the scripts to add URLS to exclude to list will be triggered. Ex: if you chose 8 and 28, the script wil run every 8 and 28 of each month.
	        </div>
	        <input class="form-control" type="text" v-model="daysNumber" placeholder="edit me" />
					<div v-if="notValid" class="text-danger">
						Days should be between 1 and 30 and seperated by commas. Ex: 1,2,3
					</div>
				</div>
	      <div class="modal-footer">
	        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
	        <button
	        	@click="saveDays"
						:disabled="notValid"
	        	type="button"
	        	class="btn btn-primary"
	        	data-bs-dismiss="modal"
	        >Save</button>
	      </div>
	    </div>
	  </div>
	</div>
</template>