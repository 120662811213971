<script>
	// Components
	import AccountHierarchyElement from "@/views/google/account_hierarchy_element.vue"

	export default {
		components: {
			AccountHierarchyElement
		},

		props: {
			isFetched: {
				type: Boolean,
				default: false
			},
	    accountsHierarchy: {
	    	type: Object,
	    	default: {}
	    },
	    accountHierarchyNeverFetched: {
	    	type: Boolean,
	    	default: true
	    },
	    accountsToDisable: {
	    	type: Array,
	    	default: []
	    }
		},

		data() {
			return {
				checkedAccountsToAdd: [],
			}
		},

		mounted() {
			const element = this.$refs.myModal
			this.modal = new bootstrap.Modal(element)
			this.modal.show()
			element.addEventListener('hidden.bs.modal', event => {
				this.$emit('hide')
			})
		},

		methods: {
			toggleAccount(account) {
	  		if(this.checkedAccountsToAdd.find(checkedAccount => account.description.id === checkedAccount.description.id)) {
	  			this.checkedAccountsToAdd = this.checkedAccountsToAdd.filter(checkedAccount => account.description.id !== checkedAccount.description.id)
	  		} else {
	  			this.checkedAccountsToAdd.push(account)
	  		}
	  	},

	  	addAccounts() {
	  		this.$emit('add-accounts', this.checkedAccountsToAdd)
	  		this.modal.hide()
	  	}
		}
	}
</script>
<template>
	<div ref="myModal" class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Select accounts</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      	<div class="modal-body modal-body__scrollable">
					<template v-if="isFetched">
						<template v-if="Object.keys(accountsHierarchy).length > 0">
							<div class="p-2 bg-light rounded mb-3">
								You can select the Google Ads accounts you want to connect on your dashboard:
							</div>
							<template
								v-for="(value, propertyName) in accountsHierarchy"
								:key="propertyName"
							>
								<AccountHierarchyElement
									v-if="!value.description.manager || (value.description.manager && Object.keys(value.children).length > 0)"
									:account="value"
									:accounts-to-disable="accountsToDisable"
									@toggle-account="toggleAccount"
								/>
							</template>
						</template>
						<template v-else>
							You do not have any google ads account to add
						</template>
					</template>
					<div v-else
						class="d-flex justify-content-center align-items-center text-secondary"
					>
						<span class="me-2">
							<template v-if="accountHierarchyNeverFetched">
								<p>Retrieving data from Google: it can take time.</p>
								This is done only once after account creation.
							</template>
							<template>
								Fetching
							</template>
						</span>
						<div class="spinner-border" role="status">
							<span class="visually-hidden"></span>
						</div>
					</div>
				</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button
					:disabled="checkedAccountsToAdd.length == 0"
					@click="addAccounts()"
					type="button"
					class="btn btn-primary"
				>
					Add accounts
				</button>
      </div>
    </div>
  </div>
</div>
</template>