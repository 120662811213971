<script>
  import axios from 'axios';
  import moment from 'moment';
  import { createApp } from 'vue/dist/vue.esm-bundler';
  import PerformanceReportBuilderModal from "@/views/google/ads_account/performance_report_builder_modal.vue"

  export default {
    props: {
			id: {
				type: [Number, String],
				required: true
			},
		},

    data() {
      return {
        performanceReports: [],
        isFetching: false,
      }
    },

    methods: {
      buildReportModal() {
				const element = document.createElement("div")
	      document.body.appendChild(element)
	      createApp(PerformanceReportBuilderModal, {
	      	onHide: (e) => element.remove(),
          onReportCreated: (report) => {
            this.performanceReports.unshift(report)
          },
          googleAdsAccountId: this.id
	      }).mount(element)
			},

      showReportModal(report) {
        const element = document.createElement("div")
        document.body.appendChild(element)
        createApp(PerformanceReportBuilderModal, {
          onHide: (e) => element.remove(),
          googleAdsAccountId: this.id,
          createMode: false,
          reportData: report
        }).mount(element)
      },

      fetchReports() {
        this.isFetching = true
        axios.get(`/api/google/ads_accounts/${this.id}/ads_accounts/performance_reports`)
          .then(response => {
            if(response.status == 200) {
              this.performanceReports = response.data.performance_reports
              this.isFetching = false
            }
          })
      },

      formatDate(date) {
        return moment(date).format('YYYY-MM-DD')
      }
    },

    mounted() {
      this.fetchReports()
    },
  }
</script>

<template>
  <div class="container">
    <div class="my-5">
      <h2>Performance Reports</h2>
      <div class="d-flex justify-content-end">
        <button
          @click="buildReportModal"
          class="btn btn-primary"> Create Report </button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header"></div>
          <div class="table-responsive border-top">
            <table class="table w-100">
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Preconisations</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="isFetching">
                  <td colspan="4" class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr v-else v-for="report in performanceReports" :key="report.id">
                  <td>{{ report.from ? formatDate(report.from) : "-" }}</td>
                  <td>{{ report.to ? formatDate(report.to) : "-" }}</td>
                  <td> {{ report.preconisations.length }}</td>
                  <td>
                    <button
                      @click="showReportModal(report)"
                      class="btn btn-primary btn-sm float-end">Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>